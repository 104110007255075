html,
body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}
:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f3e: #232f3e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
}

.hamburger {
  display: none;
}

@media (min-width: 768px) {
  .HamburgerMenu {
    display: none;
  }
}

@media (max-width: 767px) {
  .hamburger  {
    display: flex;
    padding-top: 10px;
    margin-left: 10px;
    z-index: 10;
  }
}

@media (min-width: 768px) {
  .rm-on-lg-screen{
    display: none;
}
}


@media (max-width: 767px) {
  .remeoved-sm-screen{
    display: none;
  }
}

.section-heading {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}
.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

a {
  text-decoration: none;
  display: inline-block;
}
.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 10px;
}
.gap-30 {
  gap: 30px;
}

.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
  text-decoration: none;
}
.button:hover {
  background-color: var(--color-febd69);
  color: #131921;
  text-decoration: none;
}

/* Header Part */
.header-top-strip {
  border-bottom: 1px solid var(--color-3b4149);
  background-color: var(--color-131921);
}
.header-upper {
  background-color: var(--color-131921);
  text-decoration: none;
}
.header-bottom {
  background-color: var(--color-232f3e);
  text-decoration: none;
}
.header-upper .input-group-text {
  background-color: var(--color-febd69);
  text-decoration: none;
}
.header-upper.header-upper-links img {
  width: 35px;
  height: 35px;
  text-decoration: none;
}
.menu-linksss{
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3;
  text-transform: uppercase;
  text-decoration: none;
  
}

.header-bottom a,
.header-bottom .dropdown button {
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3;
  text-transform: uppercase;
  text-decoration: none;
}
.header-bottom .dropdown button:focus {
  box-shadow: none;
  text-decoration: none;
}
.header-bottom .dropdown-menu {
  background-color: var(--color-131921);
  width: 100%;
  transform: translate3d(0px, 57px, 0px) !important;
}
.header-bottom .dropdown-item {
  padding: 20px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--color-3b4149);
}
.header-bottom .dropdown-item:hover {
  background-color: transparent;
  color: var(--color-febd69) !important;
}

/* Footer Part */
@media (max-width: 767px) {
  .footer-top-data {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-top-data img {
    order: 2;
    margin-bottom: 10px;
  }

  .footer-top-data h2 {
    font-size: 14px; /* Adjust font size for smaller screens */
    order: 1;
    margin-bottom: 0;
  }
}



footer {
  background-color: var(--color-232f3e);
}
footer:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
footer .input-group-text {
  background-color: var(--color-232f3e);
  color: white;
  padding: 8px 17px;
}

/* Home Page */
.main-banner-content {
  top: 15%;
  left: 5%;
}
.main-banner-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.main-banner-content h5 {
  font-size: 42px;
  line-height: 54px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}
.main-banner-content p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}
.small-banner-content {
  top: 25%;
  left: 10%;
}
.small-banner-content h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.small-banner-content h5 {
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}
.small-banner-content p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}
.small-banner {
  width: 49%;
}

/* Home Wrapper 2 */
.home-wrapper-2 {
  background-color: var(--color-f5f5f7);
}

/* Categories Section */
.categories {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
.categories > div {
  width: 25%;
  padding: 10px 10px 10px 20px;
}
.categories > div > div {
  width: 60%;
}
.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3),
.categories > div:nth-child(4) {
  border-bottom: 1px solid var(--color-ededed);
}
.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3) {
  border-right: 1px solid var(--color-ededed);
}
.categories > div:nth-child(5),
.categories > div:nth-child(6),
.categories > div:nth-child(7) {
  border-right: 1px solid var(--color-ededed);
}

/* Blog Part */
.blog-card {
  background-color: white;
  border-radius: 10px;
}
.blog-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog-content {
  padding: 15px;
}
.blog-contentp.date {
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-777777);
  padding: 0;
}
.blog-content h5 {
  font-size: 18px;
  color: var(--color-131921);
}
.blog-content p.desc {
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}
.single-blog-card a {
  font-size: 18px;
  color: var(--color-777777);
  margin-bottom: 20px;
}
.single-blog-card h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b);
}
.single-blog-card p {
  color: var(--color-777777);
  font-size: 14px;
}

/* Product Card */
.product-card {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.product-card .product-image {
  height: 270px;
}
.product-card .product-image img {
  object-fit: contain;
}
.product-card .product-image > img:last-child {
  display: none;
}
.product-card:hover .product-image > img:nth-child(1) {
  display: none;
}
.product-card:hover .product-image > img:last-child {
  display: block;
}
.product-card:hover .action-bar {
  right: 15px;
}
.product-card .product-details h6 {
  color: var(--color-bf4800);
  font-size: 13px;
}
.product-card .product-details h5 {
  font-size: 15px;
  color: var(--color-1c1c1b);
}
.product-card .product-details p.price {
  font-size: 16px;
  color: var(--color-1c1c1b);
}
.product-card p.description {
  font-size: 13px;
  color: var(--color-777777);
  margin-right: 20px;
}

.action-bar {
  top: 10%;
  right: -23px;
  transition: 0.3s;
}
.wishlist-icon {
  top: 2%;
  right: 15px;
}

/* Special Product */
.special-product-card {
  padding: 20px 10px;
  border-radius: 10px;
  background-color: white;
}

/* Famous Card */
.famous-card .famous-content {
  top: 10%;
  left: 5%;
}
.famous-card img {
  border-radius: 10px;
}
.famous-content h5 {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400px;
  color: white;
  margin-bottom: 10px;
}
.famous-content h6 {
  font-size: 18px;
  font-weight: 500px;
  color: white;
  margin-bottom: 20px;
}
.famous-content p {
  font-size: 13px;
  line-height: 18px;
  color: white;
}

/* Custom Grid */
.custom-grid .grid {
  display: grid;
}
.custom-grid .grid-1 {
  grid-template-columns: 1fr;
}
.custom-grid .grid-2 {
  grid-template-columns: 1fr 1fr;
}
.custom-grid .grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.custom-grid .grid-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

/* Custom Flex */
.custom-flex .flex {
  display: flex;
}
.custom-flex .flex-column {
  flex-direction: column;
}
.custom-flex .flex-row {
  flex-direction: row;
}
.custom-flex .align-items-center {
  align-items: center;
}
.custom-flex .justify-content-center {
  justify-content: center;
}
.custom-flex .justify-content-between {
  justify-content: space-between;
}
.custom-flex .justify-content-around {
  justify-content: space-around;
}

/* Custom Width */
.custom-width .w-100 {
  width: 100%;
}
.custom-width .w-50 {
  width: 50%;
}
.custom-width .w-40 {
  width: 40%;
}
.custom-width .w-33 {
  width: 33%;
}
.custom-width .w-20 {
  width: 20%;
}
.custom-width .w-15 {
  width: 15%;
}
.custom-width .w-25 {
  width: 25%;
}

/* Custom Height */
.custom-height .vh-100 {
  height: 100vh;
}
.custom-height .vh-75 {
  height: 75vh;
}
.custom-height .vh-50 {
  height: 50vh;
}
.custom-height .vh-25 {
  height: 25vh;
}

/* Custom Margin */
.custom-margin .m-5 {
  margin: 5px;
}
.custom-margin .m-10 {
  margin: 10px;
}
.custom-margin .m-15 {
  margin: 15px;
}
.custom-margin .m-20 {
  margin: 20px;
}
.custom-margin .m-25 {
  margin: 25px;
}
.custom-margin .m-30 {
  margin: 30px;
}

/* Custom Padding */
.custom-padding .p-5 {
  padding: 5px;
}
.custom-padding .p-10 {
  padding: 10px;
}
.custom-padding .p-15 {
  padding: 15px;
}
.custom-padding .p-20 {
  padding: 20px;
}
.custom-padding .p-25 {
  padding: 25px;
}
.custom-padding .p-30 {
  padding: 30px;
}

@media (max-width: 1200px) {
  .categories > div {
    width: 33.33%;
  }
}

@media (max-width: 992px) {
  .small-banner {
    width: 100%;
  }
  .categories > div {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .header-upper .input-group {
    width: 80%;
  }
  .header-upper .input-group-text {
    font-size: 12px;
    padding: 10px 15px;
  }
  .main-banner-content h5 {
    font-size: 32px;
    line-height: 44px;
  }
  .categories > div {
    width: 100%;
  }
  .categories > div > div {
    width: 100%;
  }
  .blog-content h5 {
    font-size: 16px;
  }
  .blog-content p.desc {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .header-upper .input-group {
    width: 100%;
  }
  .main-banner-content {
    top: 10%;
    left: 3%;
  }
  .main-banner-content h5 {
    font-size: 28px;
    line-height: 38px;
  }
  .main-banner-content p {
    font-size: 16px;
    line-height: 24px;
  }
  .categories {
    padding: 10px;
  }
  .categories > div {
    padding: 5px;
  }
}
